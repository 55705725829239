<template>
	<div class="look_people" >
		<div class="home_center" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">

			<div class="">
				<div class="center_left">
					<div class="left_title">
						<!-- 体裁 -->{{$t('job_detail.TC')}}
					</div>
					<el-checkbox-group v-model="listData.skill">
						<template v-for="item in skillList">
							<div class="typeTitle" :class="{open:showids.indexOf(item.value)>-1}" @click="toggleSkill(item.value)">{{item.label}}</div>
							<div v-if="showids.indexOf(item.value)>-1"><el-checkbox :label="sitem.label" v-for="sitem in item.children" :key="sitem.value" style="margin: 10px 0 0;" ></el-checkbox></div>
						</template>
					</el-checkbox-group>
					<div class="left_title">
						<!-- 运行状况 -->{{$t('YXZK')}}
					</div>
					<el-checkbox-group v-model="listData.working_status">
						<!-- <el-radio label="">{{$t('activity.QB')}}</el-radio> -->
						<el-checkbox :label="item.data_code/1" v-for="item in workingStatus" :key="item.data_code">{{item.data_value}}</el-checkbox>
					</el-checkbox-group>
					<div class="left_title">
						<!-- 报酬 -->{{$t('job_detail.BC')}}
					</div>
					<div class="left_input_box">
						<el-input v-model="listData.wage_low" placeholder="" class="input"></el-input>
						<div class="input_box">
							<!-- 以上 -->{{$t('MIN')}}〜
						</div>
					</div>
					<div class="left_input_box">
						<el-input v-model="listData.wage_high" placeholder="" class="input"></el-input>
						<div class="input_box">
							{{$t('MAX')}}〜
						</div>
					</div>
				</div>

			</div>


			<div class="center_people">
				<div class="people_search">
					
					<div class="search_box">
						<img src="../../assets/img/search.png" class="search_img" alt="">
						<!-- <input type="text" class="search_input" placeholder="用关键字搜索"  v-module="listData.keyword"> -->
						<el-input :placeholder="$t('USEKEYWORD2')" class="search_input"  v-model="listData.keyword" clearable @keyup.enter.native="" ></el-input>
					</div>
					<div class="search_menu">
						<div class="menu" :class="{active:sortIndex==0}" @click="sort('score',0)">
							<!-- 评分排序 -->{{$t('other.PFPX')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.score=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.score=='ASC'"></i>
						</div>
						<div class="menu" :class="{active:sortIndex==1}" @click="sort('curr_login_time',1)">
							<!-- 登录时间排序 -->{{$t('other.DLSJPX')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.curr_login_time=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.curr_login_time=='ASC'"></i>
						</div>
						<div class="menu" :class="{active:sortIndex==2}" @click="sort('hope_wage',2)">
							<!-- 时薪排序 -->{{$t('other.XCPX')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.hope_wage=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.hope_wage=='ASC'"></i>
						</div>
						<div class="menu" :class="{active:sortIndex==3}" @click="sort('user_id',3)">
							<!-- 注册时间 -->{{$t('other.ZCSJ')}}
							<i class="el-icon-arrow-down" v-if="listData.sortRules.user_id=='DESC'"></i>
							<i class="el-icon-arrow-up" v-if="listData.sortRules.user_id=='ASC'"></i>
						</div>
					</div>
				</div>
				<div class="people_list" >
					
					
						<div class="list_box" v-for="item in list" :key="item.resume_id">
							<!-- <router-link :to='"/people_page?user_id="+item.user.user_id' > -->
							<img :src="item.user.avatar_url" style="cursor: pointer; object-fit: cover;" class="box_img" alt="" @click="toUserPage(item.user.user_id,item.user.user_role)">
							<!-- </router-link> -->
							<div class="box_name">
								{{item.user.nickname}}
							</div>
							<div class="box_text">
								{{item.user.profession||'-'}}
							</div>
							<div class="box_text">
								{{$t('SKILL')}}：{{item.user.skills.join(',')}}
							</div>
							<div class="box_text">
								<!-- 运行状况 -->{{$t('YXZK')}}：{{$util.getUnitVal(workingStatus,item.working_status)}}
							</div>
							<!-- <div class="box_text">
								评估：
							</div> -->
							<div class="box_text">
								<!-- 时间单价 -->{{$t('SJDJ')}}：{{item.hope_wage}}{{item.salary_unit}}
							</div>
							<el-button type="primary" @click.stop="senmsg(item.user.user_id)"><!-- 发送消息 -->{{$t('FSXX')}}</el-button>
						</div>
					
					
				</div>


			</div>
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		data() {
			return {
				loading:false,
				listData:{
					keyword: '',				    
				    pageSize: 15,
				   working_status:[],
				    wage_low: '',
				    wage_high: '',
				    skill: [],
				    sortRules: {
				      score: 'DESC',
				      curr_login_time: '',
				      hope_wage: '',
				      user_id: ''
				    }
				},
				currentPage: 1,
				isLast:0,
				list:[],
				skillList:[],
				workingStatus:[],
				sortIndex:0,
				
				showallskill:false,
				tenskill:[],
				allskill:[],
				showids:[]
			};
		},
		components: {
			Homeright,
		},
		created() {
			this.getSkill();
			this.getUnit();
			this.getList();
			scroll.scrollToBottom(this.getList);
		},
		computed:{
		    watchlistData(){
		        return JSON.parse(JSON.stringify(this.listData));
		    }
		},
		watch:{
			watchlistData:{
				handler:function(nval,oval){
					// console.log(oval);
					// console.log(nval);
					for(let i in nval){
						if(nval[i]!=oval[i]){
							//console.log(i);
							if(i=='keyword'||i=='wage_low'||i=='wage_high'){
								this.search();
								return;
							}
						}
					}
					this.getList('reload');
				},
				deep:true			
			},
			
		},
		methods:{
			toggleSkill(id){
				if(this.showids.indexOf(id)==-1){
					this.showids=[...this.showids,...[id]];
				}else{
					this.showids.splice(this.showids.indexOf(id),1);
				}
			},
			senmsg(id){
				if(this.$local.get('t_k')){
					this.$emit('chat',{user_id:id});
				}else{
					let lang=this.$local.get('lang');
					this.$local.clear();
					this.$local.set('lang',lang);
					
					this.$router.push({path:'/login'});
				}
				
			},
			//排序
			sort(key,index){
				for(let i in this.listData.sortRules){
					if(i==key){
						this.listData.sortRules[key]=this.listData.sortRules[key]=='DESC'?'ASC':'DESC';
					}else{
						this.listData.sortRules[i]='';
					}
				}
				
				this.sortIndex=index;
			},
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				this.skillList=res.data.records;
				let list=[],list2=[];
				for (var i = 0; i < this.skillList.length; i++) {
					if(list2.length<10){
						list.push({value:this.skillList[i].value,label:this.skillList[i].label,children:[]});
					}
					for (var a = 0; a < this.skillList[i].children.length; a++) {
						list2.push(this.skillList[i].children[a]);
						if(list2.length<=10){
							list[i].children.push(this.skillList[i].children[a]);
						}
						
					}
				}
				this.tenskill=list;
				this.allskill=list2;
				console.log('技能',list,list2);
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.workingStatus=res.data.working_status.data;
				
			},
			search:debounce(function(e){
				console.log(e);
				this.getList('reload');
			}),
			//获取列表
			async getList(t){
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;
						  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				console.log('加载数据');
				this.loading=true;
				let data=JSON.parse(JSON.stringify(this.listData));
				data.currentPage=this.currentPage;
				let res=await this.$request.post('/api/resume/findPage',data);
				this.loading=false;
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				
				if(this.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.currentPage+=1;
				}
				
			},
			
			
		}
	};
</script>

<style lang="less"  >
	.typeTitle{cursor: pointer; font-size: 14px;margin: 20px 0 5px;color: rgba(#fff,.75);font-weight: bold;
		&:before{content:"";border-left: 6px solid rgba(#fff,.75);border-top: 6px solid transparent;border-bottom: 6px solid transparent;line-height: 1.2;font-size: 0;vertical-align: middle;margin-right: 5px;display: inline-block;}		
		&.open:before{transform: rotateZ(90deg);}
	}
	.look_people {
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 733px;
			background: #567BB6;
			border-radius: 3px;
			padding: 30px;
			box-sizing: border-box;

			.left_title {
				height: 30px;
				font-size: 15px;
				border-bottom: 1px solid #fff;
				color: #fff;
			}
			.left_input_box{
				display: flex;
				align-items: center;
				color: #fff;
				margin-top: 15px;
				font-size: 15px;
				.input{
					width: 150px;
					height: 34px;
					background: #FFFFFF;
					border-radius: 7px;
					border: 1px solid #567BB6;
					margin-right: 8px;
				}
			}
			.el-checkbox-group {
				margin-bottom: 30px;
			}

			.el-radio-group{display: block;margin-bottom: 30px;}
			.el-radio{display: block; margin: 15px 0; color: #fff;}
			.el-checkbox {
				width: 100%;
				margin: 10px 0;
				color: #fff;
			}
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			.people_search {
				padding: 20px;
				box-sizing: border-box;
				width: 878px;
				min-height: 101px;
				background: #000000;
				border-radius: 7px;
				margin: 0 15px;
				margin-bottom: 30px;
				margin-top: 0;
				.search_box {
					width: 837px;
					height: 34px;
					border-radius: 7px;
					border: 1px solid #567BB6;
					display: flex;
					align-items: center;
					padding-left: 15px;
					box-sizing: border-box;
					.el-input__inner{background: none;height:100%;padding: 0 30px 0 0;border:none;color:#fff;}
					.el-input__suffix{top:-12px;right: 0;}
					.search_img {
						width: 20px;
						height: 20px;
					}

					.search_input {
						background: #000000;
						color: #fff;
						border: none;
						font-size: 14px;
						margin-left: 10px;
					}
				}

				.search_menu {
					display: flex;
					align-items: center;
					color: #fff;
					font-size: 14px;
					padding-top: 20px;

					.menu {
						margin-right: 30px;
					}

					.active {
						margin-right: 30px;
						font-weight: bold;
						color: #567BB6;
					}
				}
			}

			.people_list {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				width: 878px;

				.list_box {
					margin: 9px;
					width: 200px;

					.box_img {
						width: 200px;
						height: 210px;
						border-radius: 5px;
						margin-bottom: 16px;
					}

					.box_name {
						font-size: 14px;
						font-weight: bold;
						width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 10px;
						color: #fff;
					}

					.box_text {
						font-size: 12px;
						width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 10px;
						color: #fff;
					}

					.el-button {
						width: 200px;
						height: 21px;
						background: #567BB6;
						border-radius: 3px;
						font-size: 12px;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
					}
				}
			}

		}
	}
</style>
